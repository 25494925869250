import React from 'react';
import Script from 'next/script';
import _ from 'lodash';
import '../styles/globals.scss';
import '@wix/design-system/styles.global.css';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createEssentials } from '@wix/fe-essentials-standalone';
// TODO: should be fetched on runtime, currently the version is pinned!
import { RootEssentialsProvider } from '@wix/fe-essentials-standalone/react';
import { I18nextProvider, initI18n } from '@wix/wix-i18n-config';
import type { InitialState } from '@common/types';

const isProd = process.env.NODE_ENV === 'production';

type MyAppProps = AppProps & {
  initialState: InitialState;
};

function MyApp({ Component, pageProps, initialState }: MyAppProps) {
  initialState = initialState || getBrowserInitialState();
  const baseURL = initialState.baseURL.replace(
    '/marketplace/',
    '/partners-marketplace',
  );

  const essentials = createEssentials({
    environment: {
      language: initialState.language,
      artifactId: initialState.artifactId,
      version: initialState.artifactVersion,
    },
    experiments: { bag: initialState.experiments },
    fedops: {},
    errorMonitor: {
      dsn: 'https://7236953e4400460e850ac619ca1c45aa@sentry.wixpress.com/3949',
    },
    httpClient: {
      baseURL,
    },
  });

  const i18n = initI18n({
    locale: initialState.language,
    messages: initialState.messages,
  });

  const isMobileUserAgent = initialState?.isMobileUserAgent;

  function invokeWixTagManager() {
    if (window.wixTagManager) {
      window.wixTagManager.init(
        window.wixTagManager.HOST_NAMES.ARENA,
        initialState.language,
      );
    }
  }

  function invokeCookieConsent() {
    if (window.consentPolicyManager) {
      window.consentPolicyManager.init({
        baseUrl: initialState.baseURL,
        consentPolicy: {
          functional: true,
          analytics: true,
          advertising: false,
          dataToThirdParty: true,
          essential: true,
        },
      });
    }
  }

  function shouldIncludeCookieConsentScript() {
    const routesToInclude = ['hire', 'templates', 'template', 'wix-partner'];
    const routesToExclude = ['template/redemption'];
    return (
      isProd &&
      _.some(
        routesToInclude,
        (val: string) => initialState.pathname.indexOf(val) !== -1,
      ) &&
      _.some(
        routesToExclude,
        (val: string) => initialState.pathname.indexOf(val) === -1,
      )
    );
  }

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  return (
    <RootEssentialsProvider essentials={essentials}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} isMobileUserAgent={isMobileUserAgent} />
          {shouldIncludeCookieConsentScript() && (
            <>
              <Script
                id="cookieConsent"
                onLoad={invokeCookieConsent}
                src={`${initialState?.cookieConsent}/app.bundle.min.js`}
              />
              <Script
                id="tagManager"
                onLoad={invokeWixTagManager}
                src={`${initialState?.tagManager}/hostTags.bundle.min.js`}
              />
              <Script
                id="wixRecorder"
                src="//static.parastorage.com/unpkg-semver/wix-recorder@^1/app.bundle.min.js"
              />
            </>
          )}
        </QueryClientProvider>
      </I18nextProvider>
    </RootEssentialsProvider>
  );
}

function getBrowserInitialState() {
  return JSON.parse(
    document.getElementById('__STATE__')!.textContent!,
  ) as InitialState;
}

// This one disables automatic static generation ¯\_(ツ)_/¯
// see: https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
MyApp.getInitialProps = () => ({});

export default MyApp;
